import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';

import Rooms from './components/rooms';
import CreateRoom from './components/rooms/create';
import EditRoom from './components/rooms/edit';

import Menus from './components/menus';
import CreateMenu from './components/menus/create';
import EditMenu from './components/menus/edit';

import Bills from './components/bills';
import CreateBill from './components/bills/create';
import EditBill from './components/bills/edit';

import NoPage from './components/noPage/noPage';

import jwt_decode from "jwt-decode";
const App = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    var decodedToken = jwt_decode(token, { complete: true });
    let isExpired = false;
    const dateNow = new Date();
    const now = Math.floor(dateNow.getTime() / 1000);

    if (decodedToken.exp < now)
      isExpired = true;

    if (isExpired)
      return (
        <Login />
      );

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/rooms/list" element={<Rooms />} />
          <Route path="/rooms/create" element={<CreateRoom />} />
          <Route path="/rooms/edit/:room_item_id" element={<EditRoom />} />
          <Route path="/menus" element={<Menus />} />
          <Route path="/menus/list" element={<Menus />} />
          <Route path="/menus/edit/:menu_item_id" element={<EditMenu />} />
          <Route path="/menus/create" element={<CreateMenu />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/bills/list" element={<Bills />} />
          <Route path="/bills/create" element={<CreateBill />} />
          <Route path="/bills/edit/:bill_id" element={<EditBill />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const Logout = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
};

export default App;