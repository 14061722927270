import React from 'react';
import { Link } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Breadcrumb from './breadcrumb';
import jwt_decode from "jwt-decode";
import './default.scss'

const Layout = ({ mainContent, title }) => {
    const token = localStorage.getItem('token');
    var user = jwt_decode(token);
    return (
        <div className='wrapper'>
            <Header user={user} />
            <main>
                <Breadcrumb />
                <div className='container-fluid'>
                    {process.env.API_BASE_URL}
                    {title ? <h1 className='text-center page-title'>{title}</h1> : ''}
                    {mainContent}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Layout;