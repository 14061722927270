import React from 'react';
import Layout from '../layout/default';

const NoPage = () => {
    const content =
        <div className="container">
            <div className="p-404">
                <div className="title">
                    <h1>404</h1>
                    <h2>Page not found</h2>
                </div>
            </div>
        </div>
    return (
        <Layout mainContent={content} />
    );
};

export default NoPage;