import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = () => {
    let pathArray = window.location.href.replace(`${window.location.origin}/`, '').split('/');
    return (
        <>
            <div className='container'>
                <div className='breadcrumb'>
                    {(pathArray.length > 0 && pathArray[0] !== "") ? <ul>
                        <li><Link to={`/`}>dashboard</Link></li>
                        {(pathArray.length > 1) ? <li><Link to={`/${pathArray[0]}`}>{pathArray[0]}</Link></li> : <li>{pathArray[0]}</li>}
                        {(pathArray.length > 1) ? <li>{pathArray[1]}</li> : ''}
                    </ul> : ''}
                </div>
            </div>
        </>
    )

};

export default Breadcrumb;