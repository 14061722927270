import React from 'react';
import Layout from '../layout/default';
import jwt_decode from "jwt-decode";

const Dashboard = () => {
    const token = localStorage.getItem('token');
    var user = jwt_decode(token);
    const URL = `${process.env.REACT_APP_API_BASE_URL}/bills?limit=5`;
    const content = <div className='dashboard content'>
        <div className='container'>
            <h4 className="alert-heading mb-4">Hi {user.username}!</h4>
            <div className='row table-content mb-4'>
                <div className='col-6 dataTables_wrapper'>
                    <h5>Recent Bills</h5>
                    <table className='mb-2 table table-striped'>
                        <thead>
                            <tr>
                                <th>Bill#</th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='col-6 dataTables_wrapper'>
                    <h5>Recent Customers</h5>
                    <table className='mb-2 table table-striped'>
                        <thead>
                            <tr>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div className='row table-content'>
                <div className='col-6 dataTables_wrapper'>
                    <h5>Bills Stats</h5>
                    <table className='mb-2 table table-striped'>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Bills Count</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='col-6 dataTables_wrapper'>
                    <h5>Recent Customers</h5>
                    <table className='mb-2 table table-striped'>
                        <thead>
                            <tr>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    return (
        <Layout mainContent={content} title='Dashboard' />
    )
};

export default Dashboard;