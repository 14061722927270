import React, { useState } from 'react';
import axios from 'axios';
import Layout from '../layout/default';
import Loader from '../layout/loader';

const CreateMenu = () => {
    const URL = `${process.env.REACT_APP_API_BASE_URL}/menus`;
    const token = localStorage.getItem('token');
    const [showLoader, setShowLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [formValue, setformValue] = useState({
        name: '',
        price: 0
    });
    const handleSubmit = async e => {
        e.preventDefault();
        document.getElementsByTagName('form')[0].classList.add('disable');
        setShowLoader(true);
        axios.post(URL, {
            ...formValue
        }, {
            headers: {
                'access-token': token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Menu Added successfully.</div>)
            setShowLoader(false);
            setTimeout(function () {
                window.location.href = '/menus';
            }, 500)
            document.getElementsByTagName('form')[0].classList.remove('disable');
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        });
    };

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const content = <div className='content create-menu'>
        <div className='container'>
            {showLoader ? <Loader /> : ''}
            {responseMessage}
            <form onSubmit={handleSubmit}>
                <div className='form-row row mb-4'>
                    <div className='form-group col-6'>
                        <label for="name">Menu Item Name *</label>
                        <input type="text" name='name' className="form-control" itemID="name" placeholder="Menu Item name *" onChange={handleChange} defaultValue={formValue.name} required />
                    </div>
                    <div className='form-group col-6'>
                        <label for="name">Price *</label>
                        <input type="text" name='price' className="form-control" itemID="price" placeholder="Price *" onChange={handleChange} defaultValue={formValue.price} required />
                    </div>
                </div>
                <div className='form-group actions'>
                    <button className='btn btn-primary' type='submit'>Create</button>
                    <button className='btn outline-secondary' type='reset'>Reset</button>
                </div>
            </form>
        </div>
    </div>
    return (
        <Layout mainContent={content} title="Create New Menu Item" />
    );
}

export default CreateMenu;