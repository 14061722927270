import React from 'react';
import { NavLink } from 'react-router-dom';

const Dropdown = ({ submenus }) => {
    return (
        <ul className="dropdown">
            {submenus.map((submenu, index) => (
                <li key={index} className="menu-items">
                    <NavLink to={submenu.url} className='sidenav-link'>{submenu.title}</NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;