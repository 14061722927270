export const menuItems = [
    {
        title: 'Dashboard',
        url: '/'
    },
    {
        title: 'Rooms',
        url: '/rooms',
        submenu: [
            {
                title: 'All',
                url: '/rooms/list'
            },
            {
                title: 'Create',
                url: '/rooms/create'
            }
        ]
    },
    {
        title: 'Menus',
        url: '/menus',
        submenu: [
            {
                title: 'All',
                url: '/menus/list'
            },
            {
                title: 'Create',
                url: '/menus/create'
            }
        ]
    },
    {
        title: 'Bills',
        url: '/bills',
        submenu: [
            {
                title: 'All',
                url: '/bills/list'
            },
            {
                title: 'Create',
                url: '/bills/create'
            }
        ]
    }
];