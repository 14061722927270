import jwt_decode from "jwt-decode";
import React, { useState } from 'react';
import { menuItems } from "../../menuItems";
import MenuItems from "./menuItems";
const Navigation = () => {
    const token = localStorage.getItem('token');
    var user = jwt_decode(token);
    const [toggled, setToggled] = useState();

    const changeToggled = () => {
        if (toggled) {
            setToggled(false);
            document.body.classList.remove('nav-toggled')
        } else {
            setToggled(true);
            document.body.classList.add('nav-toggled')
        }
    };
    // let isAdmin = false;
    // if(user.type===0) isAdmin = true;
    let className = (toggled ? "navigation toggled navbar-dark" : "navigation navbar-dark");
    return (
        <div className={className}>
            <span className="navbar-toggler" onClick={changeToggled}>
                <span className="navbar-toggler-icon"></span>
                <span className="glyphicon glyphicon-remove"></span>
            </span>
            <div className="sidenav theme-scroll">
                <div className="nav-head">
                    <img src="https://github.com/mdo.png" alt={user.username} title={user.username} width="55" height="55" className="rounded-circle user" />
                    <h5 className="h4"> {user.name}<span className="small">{user.username}</span></h5>
                </div>
                <ul className="sidenav-menu">
                    {menuItems.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                    })}
                </ul>
            </div>
            {toggled ? <div className='mask nav-mask' onClick={changeToggled}></div> : ""}
        </div>
    )
};

export default Navigation;