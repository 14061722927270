import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Navigation from './navigation';
import Notifications from './notifications';

const Header = ({ user }) => {
    return (
        <header className="p-3 mb-3 border-bottom">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <Navigation />
                <div className='me-lg-auto'>
                    <Link to="/"><img src={logo} className="logo" alt='logo' /></Link>
                </div>

                <div className="dropdown text-end d-flex flex-wrap">
                    <Notifications />
                    <div className='toggler'>
                        <Link to="#" className="d-block link-light text-decoration-none dropdown-toggle" id="usermenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://github.com/mdo.png" alt={user.username} title={user.username} width="32" height="32" className="rounded-circle" />
                        </Link>
                        <ul className="dropdown-menu text-small" aria-labelledby="usermenu">
                            <li><NavLink to="/profile" className='dropdown-item'>My Profile</NavLink></li>
                            <li><NavLink to="/logout" className='dropdown-item'>Logout</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;