import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from './loader';
import Moment from 'react-moment';

const fetchNotifications = async (page = 1, limit = 10) => {
    const token = localStorage.getItem('token');
    const requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'authorization': token },
    };
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notification?limit=${limit}&page=${page}`, requestOptions);
    const jsonData = await response.json();
    return jsonData

};

const Notifications = () => {
    const [notidom, setNotidom] = useState();

    const toggler = async () => {
        setNotidom(<li className='text-center'><Loader /></li>);
        const ele = document.getElementById('notifications');
        if (ele.classList.contains('show')) {
            const jsonData = await fetchNotifications();
            if (jsonData.success) {
                console.log(jsonData.data);
                if (jsonData.data) {
                    const data = jsonData.data.docs;
                    setNotidom(data.map(li => (
                        <li key={li._id} className={(li.read === false ? "unread" : "read")}>
                            <Link to="#" className='dropdown-item' data-bs-toggle="modal" data-bs-target="#notifyModal">
                                <div className='d-flex justify-content-between'><strong>{(li.workOrder ? li.workOrder.id : li.module)}</strong> <span className='moment'><Moment fromNow>{li.createdAt}</Moment></span></div>
                                <p>{li.description.substring(0, 40)}{(li.description.length > 40) ? "..." : ""}</p>
                            </Link>
                        </li>
                    )));
                }
            } else {
                console.log(jsonData)
            }
        }
    };

    return (
        <div className='notify toggler me-4'>
            <Link to="#" className="d-block link-light text-decoration-none" id="notifications" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg>
            </Link>
            <ul className="dropdown-menu text-small theme-scroll" aria-labelledby="notifications">
                {notidom}
            </ul>
            <div className="modal" tabIndex="-1" id='notifyModal' aria-labelledby="notifyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Modal body text goes here.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary theme-button" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Notifications;