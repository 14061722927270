import axios from 'axios';
import React, { useState } from 'react';
import Layout from '../layout/guest';

const Login = () => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [showLoader, setShowLoader] = useState();
    const [error, setError] = useState();

    const URL = `${process.env.REACT_APP_API_BASE_URL}/auth/login`;

    const loginHandler = async e => {
        e.preventDefault();
        setShowLoader(true);
        await axios.post(URL, {
            username,
            password
        }).then(response => {
            const token = response.data.token;
            localStorage.setItem('token', token);
            window.location.href = '/';
        }).catch(error => {
            setError(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
        setShowLoader(false);
    };

    const content = <div className='login-form'>
        <h2 className="fw-bold mb-5">Login</h2>
        {error}
        <form onSubmit={loginHandler}>
            <div className="form-outline form-white mb-4">
                <input type="text" className="form-control form-control-lg" placeholder='username or email' onChange={e => setUsername(e.target.value)} />
            </div>

            <div className="form-outline form-white mb-4">
                <input type="password" className="form-control form-control-lg" placeholder='password' onChange={e => setPassword(e.target.value)} />
            </div>

            <p className="mb-3 pb-lg-2"><a className="text-white-50" href="/forgot-password">Forgot password?</a></p>
            {showLoader ? <div className="lds-ellipsis btn-loader"><div></div><div></div><div></div><div></div></div> : <button className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>}
        </form>
    </div>;

    return (
        <Layout mainContent={content} />
    );
};

export default Login;