import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Layout from '../layout/default';
import Loader from '../layout/loader';

const EditMenu = () => {
    const { menu_item_id } = useParams();
    const URL = `${process.env.REACT_APP_API_BASE_URL}/menus`;
    const token = localStorage.getItem('token');
    const [showLoader, setShowLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [formValue, setformValue] = useState({
        name: '',
        price: 0
    });
    const getMenuItem = () => {
        axios.get(`${URL}/${menu_item_id}`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setformValue({ ...formValue, ...response.data.data })
            console.log(formValue)
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        });
    };
    useEffect(() => {
        getMenuItem();
    }, [URL, token])
    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }
    const handleSubmit = async e => {
        e.preventDefault();
        document.getElementsByTagName('form')[0].classList.add('disable');
        setShowLoader(true);
        axios.put(`${URL}/${menu_item_id}`, {
            ...formValue
        }, {
            headers: {
                'access-token': token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Menu Updated successfully.</div>)
            setShowLoader(false);

            document.getElementsByTagName('form')[0].classList.remove('disable');
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        });
    };
    const handleBack = e => {
        e.preventDefault();
        window.location.href = '/menus';
    }
    const content = <div className='content create-menu'>
        <div className='container'>
            {showLoader ? <Loader /> : ''}
            {responseMessage}
            <form onSubmit={handleSubmit}>
                <div className='form-row row mb-4'>
                    <div className='form-group col-6'>
                        <label for="name">Menu Item Name *</label>
                        <input type="text" name='name' className="form-control" itemID="name" placeholder="Menu Item name *" onChange={handleChange} value={formValue.name} required />
                    </div>
                    <div className='form-group col-6'>
                        <label for="name">Price *</label>
                        <input type="text" name='price' className="form-control" itemID="price" placeholder="Price *" onChange={handleChange} value={formValue.price} required />
                    </div>
                </div>
                <div className='form-group actions'>
                    <button className='btn btn-primary' type='submit'>Update</button>
                    <button className='btn outline-secondary' type='button' onClick={handleBack}>Back</button>
                </div>
            </form>
        </div>
    </div>
    return (
        <Layout mainContent={content} title="Update Menu Item" />
    );
};

export default EditMenu;