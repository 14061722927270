import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Layout from '../layout/default';
import Loader from '../layout/loader';

const EditBill = () => {
    const { bill_id } = useParams();
    const URL = `${process.env.REACT_APP_API_BASE_URL}/bills`;
    const token = localStorage.getItem('token');
    const [billData, setBillData] = useState([]);
    const [billItem, setBillItem] = useState({ id: null, item_id: null, type: '', quantity: 1, rate: 0.00, price: 0.00 });
    const [foodItems, setFoodItems] = useState([]);
    const [roomItems, setRoomItems] = useState([]);
    const [itemOptions, setItemOptions] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [subTotal, setSubTotal] = useState(0);
    const [foodTax, setFoodTax] = useState(0);
    const [roomtax, setRoomTax] = useState(0);

    const getBill = () => {
        axios.get(`${URL}/${bill_id}`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setBillData(response.data.data)
            let sub = 0, ft = 0, rt = 0;
            response.data.data.items.forEach(item => {
                if (item.type === "room") rt += item.price;
                else ft += item.price;
            });
            sub = parseFloat(ft) + parseFloat(rt);
            setSubTotal(sub);
            setFoodTax(parseFloat(parseFloat(ft) * 0.06).toFixed(2))
            setRoomTax(parseFloat(parseFloat(rt) * 0.12).toFixed(2))
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        });
    };

    useEffect(() => {
        getBill();
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/menus?limit=99999`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setFoodItems(response.data.data.items)
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
        })
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/rooms?limit=99999`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setRoomItems(response.data.data.items)
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
    }, [URL, token]);

    const setPrice = (qty, rate) => {
        return parseFloat(qty * rate).toFixed(2);
    }

    const handleChange = event => {
        billItem[event.target.name] = event.target.value;
        if (event.target.name === 'type') {
            let opItems = (event.target.value === 'food') ? foodItems : roomItems;
            let options = opItems.map(item => <option value={item.id}>{item.name}</option>);
            setItemOptions(options)
        }
        if (event.target.name === 'item_id') {
            let opItems = (billItem.type === 'food') ? foodItems : roomItems;

            let selected = opItems.filter(item => item.id === parseFloat(event.target.value))[0];
            billItem.rate = parseFloat(selected.price).toFixed(2);
        }

        if (event.target.name === 'item_id' || event.target.name === 'rate' || event.target.name === 'quantity') billItem.price = setPrice(parseFloat(billItem.quantity), parseFloat(billItem.rate));

        setBillItem({
            ...billItem
        });
    };

    const generateInvoice = async () => {
        axios.get(`${URL}/${bill_id}/invoice`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            window.open(`${process.env.REACT_APP_API_BASE_URL}/public/invoices/${billData.number}.pdf`, '_blank', 'noopener,noreferrer');
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
    }

    const handleItemAdd = async e => {
        e.preventDefault();
        document.getElementsByTagName('form')[0].classList.add('disable');
        setShowLoader(true);
        axios.post(`${URL}/${bill_id}`, {
            ...billItem
        }, {
            headers: {
                'access-token': token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Item {(billItem.item_uuid) ? 'updated' : 'Added'} successfully.</div>)
            setShowLoader(false);
            getBill();
            setBillItem({ id: null, item_id: null, type: '', quantity: 1, rate: 0.00, price: 0.00 })
            document.getElementsByTagName('form')[0].classList.remove('disable');
            document.getElementById("type").value = 'Select Item Type'
            document.getElementById("item_id").value = 'Select Item';
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        });
    }

    const editHandler = (e, item_id) => {
        e.preventDefault();
        let item = billData.items.filter(item => item_id === item.item_uuid)[0];
        setBillItem({ ...billItem, ...item });
        document.getElementById("type").value = item.type;
        let opItems = (document.getElementById("type").value === 'food') ? foodItems : roomItems;
        let options = opItems.map(item => <option value={item.id}>{item.name}</option>);
        setItemOptions(options)
        setTimeout(() => { document.getElementById("item_id").value = item.item_id; }, 300)

    };
    const deleteHandler = (e, item_id) => {
        e.preventDefault();
        axios.delete(`${URL}/${bill_id}/items/${item_id}`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Item deleted successfully.</div>)
            getBill()
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
    };

    const handleStatusChange = async e => {
        setShowLoader(true);
        billData.status = e.target.value;
        axios.put(`${URL}/${bill_id}`, {
            status: e.target.value
        }, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Bill updated successfully.</div>);
            getBill();
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
    }

    const content = <div className='content create-bill'>
        <div className='container'>
            {showLoader ? <Loader /> : ''}
            {responseMessage}

            <div className='row mb-4'>
                <div className='col-6'>
                    <form>
                        <table className='table bill-data'>
                            <tbody>
                                <tr><th>Bill #: </th><td>{billData.number}</td></tr>
                                {(billData.status === 3) ? <tr><th>Invoice Generated</th><td><button className='btn btn-success' type='button' onClick={generateInvoice}>Download</button></td></tr> :
                                    <tr>
                                        <th><label htmlFor="status">Status</label>: </th>
                                        <td>
                                            <select name='status' value={billData.status} onChange={handleStatusChange}>
                                                <option value="0">New</option>
                                                <option value="1">Un-Paid</option>
                                                <option value="2">Paid</option>
                                                <option value="3">Generate Invoice</option>
                                            </select>
                                        </td>
                                    </tr>}
                                <tr><th>Customer Name: </th><td>{billData.customer_name}</td></tr>
                                <tr><th>Customer Phone: </th><td>{billData.customer_phone}</td></tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div className='col-6'>
                    <table className='table bill-data pull-right'>
                        <tbody>
                            <tr><th>Sub Total: </th><td><span className='inr'>&#8377;</span> {subTotal}</td></tr>
                            <tr><th>Food GST(6%): </th><td><span className='inr'>&#8377;</span> {foodTax}</td></tr>
                            <tr><th>Room GST(12%): </th><td><span className='inr'>&#8377;</span> {roomtax}</td></tr>
                            <tr><th>Total Tax: </th><td><span className='inr'>&#8377;</span> {parseFloat(foodTax + roomtax).toFixed(2)}</td></tr>
                            <tr><td><strong>Total:</strong> </td><th><span className='inr'>&#8377;</span> {parseFloat(parseFloat(subTotal) + parseFloat(foodTax) + parseFloat(roomtax)).toFixed(2)}</th></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='row mb-6 table-content'>
                <div className='col-12 dataTables_wrapper'>
                    <form onSubmit={handleItemAdd}>
                        <table className='mb-2 table table-striped'>
                            <thead>
                                <tr>
                                    <th className='type'>Item Type</th>
                                    <th className='item'>Item</th>
                                    <th className='description'>Description</th>
                                    <th className='quantity'>Quantity</th>
                                    <th className='rate'>Rate per unit</th>
                                    <th className='price'>Price</th>
                                    <th className='action'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(billData.status !== 3) ? <tr className='form'>
                                    <td>
                                        <select id='type' name='type' onChange={handleChange} value={billData.type}>
                                            <option>Select Item Type</option>
                                            <option value='food'>Food</option>
                                            <option value='room'>Room</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select id='item_id' name='item_id' onChange={handleChange} value={billData.item_id}>
                                            <option>Select Item</option>
                                            {itemOptions}
                                        </select>
                                    </td>
                                    <td><input type="text" name="description" id="description" onChange={handleChange} value={billItem.description} /></td>
                                    <td><input type="number" name="quantity" id="quantity" value={billItem.quantity} onChange={handleChange} /></td>
                                    <td className='rate'><span className='inr'>&#8377;</span><input type="text" readOnly name="rate" id="rate" value={billItem.rate} onChange={handleChange} /></td>
                                    <td className='price'><span className='inr'>&#8377;</span><input readOnly type="text" name="price" id="price" value={billItem.price} onChange={handleChange} /></td>
                                    <td>
                                        <button className='btn btn-primary' type='submit'>Save</button>
                                        &nbsp;&nbsp;
                                        <button className='btn outline-secondary' type='reset'>Reset</button>
                                    </td>
                                </tr> : ''}
                                {(billData.items) ? billData.items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='type'>{item.type}</td>
                                            <td>{item.item}</td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity}</td>
                                            <td className='rate'><span className='inr'>&#8377;</span> {parseFloat(item.rate).toFixed(2)}</td>
                                            <td className='price'><span className='inr'>&#8377;</span> {parseFloat(parseFloat(item.rate) * parseFloat(item.quantity)).toFixed(2)}</td>
                                            <td>
                                                {(billData.status !== 3) ? <Link to="#" className="link-success" onClick={e => editHandler(e, item.item_uuid)}>Edit</Link> : ''}
                                                &nbsp;&nbsp;
                                                {(billData.status !== 3) ? <Link to="#" className="link-danger" onClick={e => deleteHandler(e, item.item_uuid)}>Delete</Link> : ''}
                                            </td>
                                        </tr>
                                    )
                                }) : ''}
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
    return (
        <Layout mainContent={content} title="Update bill and it's items" />
    );
};

export default EditBill;
