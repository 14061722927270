import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import Layout from '../layout/default';
import { Link } from 'react-router-dom';
import Loader from '../layout/loader';

const Bills = () => {
    const URL = `${process.env.REACT_APP_API_BASE_URL}/bills`;
    const token = localStorage.getItem('token');
    const [billings, setBillings] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [responseMessage, setResponseMessage] = useState();
    const billStatus = ['New', 'Un-Paid', 'Paid', 'Invoice Generated']
    const getBills = () => {
        axios.get(`${URL}/?limit=100`, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            const items = response.data.data;
            items.forEach(invoice => {
                invoice["action"] = <div className="action-dropdown">
                    <Link to={`/bills/edit/${invoice.uuid}`} className="link-success">Edit</Link>
                    &nbsp;&nbsp;
                    <Link to="#" className="link-danger" onClick={e => deleteHandler(e, invoice.uuid)}>Delete</Link>
                    &nbsp;&nbsp;
                    {(invoice.status === 3) ? <a href={`${process.env.REACT_APP_API_BASE_URL}/public/invoices/${invoice.number}.pdf`} className='link-primary' download target='_blank'>Download</a> : ''}
                </div >;
                invoice['status'] = billStatus[invoice['status']];
            })
            setBillings(items);
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
    }
    const deleteHandler = (e, uuid) => {
        e.preventDefault();
        setShowLoader(true);
        document.getElementsByClassName('dataTables_wrapper')[0].classList.add('disable');
        if (window.confirm('Are you sure you want to delete this invoice?')) {
            axios.delete(URL + '/' + uuid, {
                headers: {
                    "access-token": token
                }
            }).then(response => {
                setResponseMessage(<div className="alert alert-success" role="alert">Bill deleted successfully.</div>)
                getBills()
                document.getElementsByClassName('dataTables_wrapper')[0].classList.remove('disable');
                setShowLoader(false);
            }).catch(error => {
                setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
                document.getElementsByClassName('dataTables_wrapper')[0].classList.remove('disable');
                setShowLoader(false);
            })
        }
    }
    useEffect(() => {
        getBills();
    }, [URL, token]);
    const data = {
        columns: [
            {
                label: 'Bill#',
                field: 'number',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Customer Name',
                field: 'customer_name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Customer Phone',
                field: 'customer_phone',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Created At',
                field: 'createdAt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Updated At',
                field: 'updatedAt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 150
            }
        ],
        rows: billings
    };

    const content = <div className='content billings'>
        <div className='table-content container'>
            <div className='add-link'><Link to="/bills/create" className="link-primary">Add New Bill</Link></div>
            {showLoader ? <Loader /> : ''}
            {responseMessage}
            {<MDBDataTable striped bordered small data={data} />}
        </div>
    </div>;
    return (
        <Layout mainContent={content} title="All Bills" />
    )
}
export default Bills;