import React, { useState } from 'react';
import axios from 'axios';
import Layout from '../layout/default';
import Loader from '../layout/loader';

const CreateBill = () => {
    const URL = `${process.env.REACT_APP_API_BASE_URL}/bills`;
    const token = localStorage.getItem('token');
    const [showLoader, setShowLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [formValue, setformValue] = useState({
        customer_name: '',
        customer_phone: ''
    });
    const handleSubmit = async e => {
        e.preventDefault();
        document.getElementsByTagName('form')[0].classList.add('disable');
        setShowLoader(true);
        axios.post(URL, {
            ...formValue
        }, {
            headers: {
                'access-token': token
            }
        }).then(response => {
            setResponseMessage(<div className="alert alert-success" role="alert">Bill created successfully.</div>)
            setShowLoader(false);
            window.location.href = `/bills/edit/${response.data.data.bill_id}`;
            setTimeout(function () {
            }, 500)
            document.getElementsByTagName('form')[0].classList.remove('disable');
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        });
    };

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const searchCustomer = async () => {
        document.getElementsByTagName('form')[0].classList.add('disable');
        setShowLoader(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/customers?phone=${formValue.customer_phone}`, {
            headers: {
                'access-token': token
            }
        }).then(response => {
            if (response.data.data && response.data.data.name) {
                setResponseMessage(<div className="alert alert-success" role="alert">Customer Exists.</div>);
                setformValue({
                    customer_name: response.data.data.name,
                    customer_phone: response.data.data.phone
                })
            }
            else setResponseMessage(<div className="alert alert-danger" role="alert">Customer doesn't exists</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
            setShowLoader(false);
            document.getElementsByTagName('form')[0].classList.remove('disable');
        });
    };

    const content = <div className='content create-bill'>
        <div className='container'>
            {showLoader ? <Loader /> : ''}
            {responseMessage}
            <form onSubmit={handleSubmit}>
                <div className='form-row row mb-4'>
                    <div className='form-group col-6 with-search'>
                        <label for="customer_phone">Customer Phone *</label>
                        <input type="text" name='customer_phone' className="form-control" itemID="customer_phone" placeholder="Customer Phone *" onChange={handleChange} defaultValue={formValue.customer_phone} required />
                        <button type='button' onClick={searchCustomer}>Search</button>
                    </div>
                    <div className='form-group col-6'>
                        <label for="customer_name">Customer Name *</label>
                        <input type="text" name='customer_name' className="form-control" itemID="customer_name" placeholder="Customer Name *" onChange={handleChange} defaultValue={formValue.customer_name} required />
                    </div>
                </div>
                <div className='form-group actions'>
                    <button className='btn btn-primary' type='submit'>Create</button>
                    <button className='btn outline-secondary' type='reset'>Reset</button>
                </div>
            </form>
        </div>
    </div>
    return (
        <Layout mainContent={content} title="Create New Bill" />
    );
}

export default CreateBill;