import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import Layout from '../layout/default';
import { Link } from 'react-router-dom';
import Loader from '../layout/loader';

const Rooms = () => {
    const URL = `${process.env.REACT_APP_API_BASE_URL}/rooms`;
    const token = localStorage.getItem('token');
    const [rooms, setRooms] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [responseMessage, setResponseMessage] = useState();
    useEffect(() => {
        axios.get(URL, {
            headers: {
                "access-token": token
            }
        }).then(response => {
            const items = response.data.data.items;
            items.forEach(room => {
                room["action"] = <div className="action-dropdown">
                    <Link to={`/rooms/edit/${room.uuid}`} className="link-success">Edit</Link>
                    &nbsp;&nbsp;
                    <Link to="#" className="link-danger" onClick={e => deleteHandler(e, room.uuid)}>Delete</Link>
                </div >;
            })
            setRooms(items);
            setShowLoader(false);
        }).catch(error => {
            setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
        })
        const deleteHandler = (e, uuid) => {
            e.preventDefault();
            setShowLoader(true);
            document.getElementsByClassName('dataTables_wrapper')[0].classList.add('disable');
            if (window.confirm('Are you sure you want to delete this room?')) {
                axios.delete(URL + '/' + uuid, {
                    headers: {
                        "access-token": token
                    }
                }).then(response => {
                    setResponseMessage(<div className="alert alert-success" role="alert">Room deleted successfully.</div>)
                    setRooms((rooms) => rooms.filter((v, i) => v.uuid !== uuid));
                    document.getElementsByClassName('dataTables_wrapper')[0].classList.remove('disable');
                    setShowLoader(false);
                }).catch(error => {
                    setResponseMessage(<div className="alert alert-danger" role="alert">{error.response.data.message}</div>)
                    document.getElementsByClassName('dataTables_wrapper')[0].classList.remove('disable');
                    setShowLoader(false);
                })
            }
        }
    }, [URL, token]);

    const data = {
        columns: [
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Rooms Quantity',
                field: 'quantity',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Per Room Capacity',
                field: 'perRoomCapacity',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Price',
                field: 'price',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Sale Price',
                field: 'salePrice',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Created At',
                field: 'createdAt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Updated At',
                field: 'updatedAt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 150
            }
        ],
        rows: rooms
    };



    const content = <div className='content rooms'>
        <div className='table-content container'>
            <div className='add-link'><Link to="/rooms/create" className="link-primary">Add New Room</Link></div>
            {showLoader ? <Loader /> : ''}
            {responseMessage}
            {<MDBDataTable striped bordered small data={data} />}
        </div>
    </div>;
    return (
        <Layout mainContent={content} title="Rooms" />
    )
}
export default Rooms;