import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from './dropdown';

const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <li className="sidenav-item">
            {items.submenu ? <><NavLink to={items.url} className={dropdown ? "sidenav-link shown p-3 has-subnav" : "sidenav-link p-3 has-subnav"} onClick={(e) => { e.preventDefault(); setDropdown((prev) => !prev) }}>{items.title}</NavLink> <Dropdown submenus={items.submenu} /></> : <NavLink to={items.url} className='sidenav-link p-3'>{items.title}</NavLink>
            }
        </li >
    )
}

export default MenuItems;