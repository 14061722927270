import React from 'react';
import "./guest.scss";
import logo from '../../assets/images/logo.png';
const Layout = ({ mainContent }) => {
    return (
        <section className="guest-layout">
            <div className="image">
                <div className='img'></div>
            </div>
            <div className="form">
                <div className="card bg-theme-main text-white">
                    <div className="card-body p-5 pt-2 text-center">
                        <img src={logo} className="logo" alt='logo' />
                        {mainContent}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Layout;